.your-brand {
  background-size: cover;
  color: white;
  min-height: 393px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  h2 {
    max-width: 705px;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    letter-spacing: 0px;
  }

  p {
    max-width: 536px;
    text-align: center;
    font-family: Inter;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0px;
    margin-bottom: 39px;
  }

  @media (max-width: 560px) {
    min-height: 200px;

    p {
      margin-bottom: 0;
    }

    h2 {
      font-size: 30px;
      line-height: 1.2;
    }
  }
}